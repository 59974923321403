import { getSanityClient } from "@/services/sanityClient";
import { getArticlesByType } from "./getArticlesByType";
import groq from "groq";

export async function getAllArticlesForSearch(page, category) {
  const { articleTypes } = page.fields

  const allArticles = [];

  await Promise.all(articleTypes.map(async type => {
    const articles = await getArticlesByType(type)

    if (articles) {
      const filteredArticles = articles.filter(a => a.fields.published)
      return allArticles.push(...filteredArticles.filter(a => a.fields.blog?.handle?.current === category || a.fields.blog?.handle === category))
    }
  }))

  return allArticles.map(a => {
    const blog = a.fields ? a.fields.blog : a.blog
    return {
      handle: a.handle,
      title: a.title,
      createdAt: a.createdAt,
      fields: {
        publishedDate: a.fields.publishedDate ?? null,
        articleTags: a.fields.articleTags ?? null,
        hero: a.fields.hero ?? a.hero,
        blog: {
          blogType: blog.blogType,
          handle: blog.handle
        }
      }
    }
  })
}

export const getArticlesByHandle = async (handles, client = getSanityClient()) => {
  const articles = await client.fetch(GET_ARTICLES_BY_HANDLES(handles));
  const articlesData = articles.map((article) => {
    return {
      createdAt: article._createdAt,
      fields: {
        __typename: article._type,
        ...article,
      },
      handle: article.handle.current,
      published: true,
      sourceId: article.objectID,
      tags: [],
      title: article.title,
      type: article._type,
      updatedAt: article._updatedAt
    };
  });
  return articlesData
}

export const getStandardArticleByHandle = async (handle, client = getSanityClient()) => {
  const article = await client.fetch(GET_STANDARD_ARTICLE_BY_HANDLE(handle))
  if (!article) return undefined
  return {
    createdAt: article._createdAt,
    fields: {
      __typename: article._type,
      ...article,
    },
    handle: article.handle.current,
    published: true,
    sourceId: article.objectID,
    tags: [],
    title: article.title,
    type: article._type,
    updatedAt: article._updatedAt
  }
}

//#region GROQ QUERIES
const GET_ARTICLES_BY_HANDLES = (handles) => `
*[handle.current in [${handles.map(el => `"${el}"`).join(",")}] && published == true]
{
  ...,
  "objectID": _id,
  seo{
    ...,
    shareGraphic{
      ...,
      asset->{...}
    }
  },
  content[]{
    ...,
    asset->{...},
    iconWithTextBlock{
      ...,
    },
    icon->{
      ...,
      image{
        ...,
        asset->{...}
      },
    },
    valuePropsBlock{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp2{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp3{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp4{
      ...,
      image->{
        ...,
        asset->{...}
      },
    }
  },
  hero{
    ...,
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    }
  },
  blog->{
    ...,
    content[]{
      ...,
      image{
        ...,
        asset->{...}
      },
      footerImage{
        ...,
        asset->{...}
      },
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      },
      illustration{
        ...,
        asset->{...}
      },
      illustration2{
        ...,
        asset->{...}
      }
    },
    filterGroups[]->{
      ...
    },
    hero{
      ...,
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      }
    }
  },
  sidebar{
    ...,
    author->{
      ...,
      image{
        ...,
        asset->{...}
      }
    },
    hosts{
      ...,
      hostList[]->{
        ...,
        image->{
          ...,
          asset->{...}
        }
      }
    },
    knowYourFishList{
      ...,
      knowYourFishes[]->{
        ...,
        image{
          ...,
          asset->{...}
        },
        description->{
          ...,
        },
        content[]{
          ...,
          image{
            ...,
            asset->{...}
          },
          footerImage{
            ...,
            asset->{...}
          },
          illustration{
            ...,
            asset->{...}
          },
          illustration2{
            ...,
            asset->{...}
          },
          illustrationAlt{
            ...,
            asset->{...}
          },
          illustration2Alt{
            ...,
            asset->{...}
          },
        }
      }
    }
  },
  pageContent[]{
    ...,
    blog->{...},
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    },
    illustration{
      ...,
      asset->{...}
    },
    illustration2{
      ...,
      asset->{...}
    },
  },
  contestForm{
    ...,
    heroImage{
      ...,
      asset->{...}
    }
  }
}
`

const GET_STANDARD_ARTICLE_BY_HANDLE = (handle) => groq`
*[_type == "standardArticle" && handle.current == "${handle}"][0]
{
  ...,
  "objectID": _id,
  content[]{
    ...,
    asset->{...},
    iconWithTextBlock{
      ...,
    },
    icon->{
      ...,
      image{
        ...,
        asset->{...}
      },
    },
    valuePropsBlock{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp2{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp3{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp4{
      ...,
      image->{
        ...,
        asset->{...}
      },
    }
  },
  hero{
    ...,
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    }
  },
  blog->{
    ...,
    content[]{
      ...,
      image{
        ...,
        asset->{...}
      },
      footerImage{
        ...,
        asset->{...}
      },
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      },
      illustration{
        ...,
        asset->{...}
      },
      illustration2{
        ...,
        asset->{...}
      }
    },
    filterGroups[]->{
      ...
    },
    hero{
      ...,
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      }
    }
  },
  sidebar{
    ...,
    author->{
      ...,
      image{
        ...,
        asset->{...}
      }
    },
    knowYourFishList{
      ...,
      knowYourFishes[]->{
        ...,
        image{
          ...,
          asset->{...}
        },
        description->{
          ...,
        },
        content[]{
          ...,
          image{
            ...,
            asset->{...}
          },
          footerImage{
            ...,
            asset->{...}
          },
          illustration{
            ...,
            asset->{...}
          },
          illustration2{
            ...,
            asset->{...}
          },
          illustrationAlt{
            ...,
            asset->{...}
          },
          illustration2Alt{
            ...,
            asset->{...}
          },
        }
      }
    }
  },
  pageContent[]{
    ...,
    blog->{...},
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    },
    illustration{
      ...,
      asset->{...}
    },
    illustration2{
      ...,
      asset->{...}
    },
  }
}
`
//#endregion
