import sanityClient from '../services/sanityClient'

const QUERY = (type) => `
*[_type == "${type}"]
{
  ...,
  "objectID": _id,
  content[]{
    ...,
    asset->{...},
    iconWithTextBlock{
      ...,
    },
    icon->{
      ...,
      image{
        ...,
        asset->{...}
      },
    },
    valuePropsBlock{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp2{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp3{
      ...,
      image->{
        ...,
        asset->{...}
      },
    },
    valueProp4{
      ...,
      image->{
        ...,
        asset->{...}
      },
    }
  },
  hero{
    ...,
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    }
  },
  blog->{
    ...,
    content[]{
      ...,
      image{
        ...,
        asset->{...}
      },
      footerImage{
        ...,
        asset->{...}
      },
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      },
      illustration{
        ...,
        asset->{...}
      },
      illustration2{
        ...,
        asset->{...}
      }
    },
    filterGroups[]->{
      ...
    },
    hero{
      ...,
      desktopBackgroundImage{
        ...,
        asset->{...}
      },
      mobileBackgroundImage{
        ...,
        asset->{...}
      }
    }
  },
  sidebar{
    ...,
    author->{
      ...,
      image{
        ...,
        asset->{...}
      }
    },
    knowYourFishList{
      ...,
      knowYourFishes[]->{
        ...,
        image{
          ...,
          asset->{...}
        },
        description->{
          ...,
        },
        content[]{
          ...,
          image{
            ...,
            asset->{...}
          },
          footerImage{
            ...,
            asset->{...}
          },
          illustration{
            ...,
            asset->{...}
          },
          illustration2{
            ...,
            asset->{...}
          },
          illustrationAlt{
            ...,
            asset->{...}
          },
          illustration2Alt{
            ...,
            asset->{...}
          },
        }
      }
    }
  },
  pageContent[]{
    ...,
    desktopBackgroundImage{
      ...,
      asset->{...}
    },
    mobileBackgroundImage{
      ...,
      asset->{...}
    },
    illustration{
      ...,
      asset->{...}
    },
    illustration2{
      ...,
      asset->{...}
    },
  },
  contestForm{
    ...,
    heroImage{
      ...,
      asset->{...}
    }
  }
}
`

export const getArticlesByType = async (articleType) => {
  const articles = await sanityClient.fetch(QUERY(articleType))
  const articlesData = articles.map((article) => {
    return {
      createdAt: article._createdAt,
      fields: {
        __typename: article._type,
        ...article,
      },
      handle: article.handle.current,
      published: true,
      sourceEntryId: '',
      sourceId: article.objectID,
      tags: [],
      title: article.title,
      type: article._type,
      updatedAt: article._updatedAt,
    }
  })
  return articlesData
}
